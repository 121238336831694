import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import CustomScrollbars from 'util/CustomScrollbars';
import permissions from 'services/permissions'
import {
    CLIENTS_MODULE,
    GROUPS_MODULE,
    TRAFFIC_LIGHTS_MODULE,
    UNITS_MODULE,
    DISPERSIONS_MODULE,
    EXTRAORDINARY_RECHARGES_MODULE,
    NOTIFICATIONS_MODULE,
    CONNECTIONS_MODULE,
    ROLES_MODULE,
    USERS_MODULE,
    REPORTS_MODULE,
    DISPERSION_REPORT,
    COMBUSTIBLE_REPORT,
    TRAFFIC_LIGHTS_REPORT,
    RELOADS_REPORT,
    FILES_MODULE
} from 'constants/permissions'

class SidenavContent extends Component {

    componentDidMount() {
        const { history } = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;// get current path

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {

                const parentLiEle = that.closest(this, 'li');
                if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
                    event.stopPropagation();

                    if (menuLi[i].classList.contains('open')) {
                        menuLi[i].classList.remove('open', 'active');
                    } else {
                        menuLi[i].classList.add('open', 'active');
                    }
                } else {
                    for (let j = 0; j < menuLi.length; j++) {
                        const parentLi = that.closest(this, 'li');
                        if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                            menuLi[j].classList.remove('open');
                        } else {
                            if (menuLi[j].classList.contains('open')) {
                                menuLi[j].classList.remove('open');
                            } else {
                                menuLi[j].classList.add('open');
                            }
                        }
                    }
                }
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    componentWillReceiveProps(nextProps) {

        const { history } = nextProps;
        const pathname = `${history.location.pathname}`;// get current path
        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }

    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className=" scrollbar">
                <ul className="nav-menu">

                    {
                        permissions.hasPermission(CLIENTS_MODULE) &&
                        <li className="menu no-arrow">
                            <NavLink className="prepend-icon" to="/app/clients">
                                <i className="zmdi zmdi-account zmdi-hc-fw" />
                                <span className="nav-text">Clientes</span>
                            </NavLink>
                        </li>
                    }

                    {
                        permissions.hasPermission(GROUPS_MODULE) &&
                        <li className="menu no-arrow">
                            <NavLink className="prepend-icon" to="/app/groups">
                                <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                                <span className="nav-text">Grupos</span>
                            </NavLink>
                        </li>
                    }

                    {
                        permissions.hasPermission(TRAFFIC_LIGHTS_MODULE) &&
                        <li className="menu no-arrow">
                            <NavLink className="prepend-icon" to="/app/traffic-lights">
                                <i className="zmdi zmdi-traffic zmdi-hc-fw" />
                                <span className="nav-text">Semáforos</span>
                            </NavLink>
                        </li>
                    }

                    {
                        permissions.hasPermission(UNITS_MODULE) &&
                        <li className="menu no-arrow">
                            <NavLink className="prepend-icon" to="/app/units">
                                <i className="zmdi zmdi-car zmdi-hc-fw" />
                                <span className="nav-text">Unidades</span>
                            </NavLink>
                        </li>
                    }

                    {
                        permissions.hasPermission(DISPERSIONS_MODULE) &&
                        <li className="menu no-arrow">
                            <NavLink className="prepend-icon" to="/app/dispersions">
                                <i className="zmdi zmdi-calendar-alt zmdi-hc-fw" />
                                <span className="nav-text">Dispersiones</span>
                            </NavLink>
                        </li>
                    }

                    {
                        permissions.hasPermission(EXTRAORDINARY_RECHARGES_MODULE) &&
                        <li className="menu no-arrow">
                            <NavLink className="prepend-icon" to="/app/refills">
                                <i className="zmdi zmdi-gas-station zmdi-hc-fw" />
                                <span className="nav-text">Recargas extraordinarias</span>
                            </NavLink>
                        </li>
                    }

                    {
                        (permissions.hasPermission(NOTIFICATIONS_MODULE)
                            || permissions.hasPermission(CONNECTIONS_MODULE)
                            || permissions.hasPermission(ROLES_MODULE)
                            || permissions.hasPermission(USERS_MODULE)
                            || permissions.hasPermission(FILES_MODULE)
                        ) &&
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-settings zmdi-hc-fw" />
                                <span className="nav-text">Administración</span>
                            </Button>
                            <ul className="sub-menu">
                                {
                                    permissions.hasPermission(NOTIFICATIONS_MODULE) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/administration/notifications">
                                            <span className="nav-text">Notificaciones</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(NOTIFICATIONS_MODULE) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/administration/messages">
                                            <span className="nav-text">Mensajes</span>
                                        </NavLink>
                                    </li>
                                }
                                {/* <li>
                                    <NavLink className="prepend-icon" to="/app/administration/monitor">
                                        <span className="nav-text">Monitor de recargas</span>
                                    </NavLink>
                                </li> */}
                                {
                                    permissions.hasPermission(CONNECTIONS_MODULE) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/administration/connections">
                                            <span className="nav-text">Conexiones</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(ROLES_MODULE) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/administration/roles">
                                            <span className="nav-text">Roles</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(USERS_MODULE) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/administration/users">
                                            <span className="nav-text">Usuarios</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(FILES_MODULE) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/administration/files">
                                            <span className="nav-text">Archivos Importados</span>
                                        </NavLink>
                                    </li>
                                }
                            </ul>
                        </li>
                    }

                    {
                        permissions.hasPermission(REPORTS_MODULE) &&
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-settings zmdi-hc-fw" />
                                <span className="nav-text">Reportes</span>
                            </Button>
                            <ul className="sub-menu">
                                {
                                    permissions.hasPermission(REPORTS_MODULE, DISPERSION_REPORT) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/reports/dispersion">
                                            <span className="nav-text">Dispersión</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(REPORTS_MODULE, COMBUSTIBLE_REPORT) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/reports/gasoline">
                                            <span className="nav-text">Consumo de gasolina</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(REPORTS_MODULE, TRAFFIC_LIGHTS_REPORT) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/reports/traffic-light">
                                            <span className="nav-text">Semáforo</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    permissions.hasPermission(REPORTS_MODULE, RELOADS_REPORT) &&
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/reports/reloads">
                                            <span className="nav-text">Gasolineras</span>
                                        </NavLink>
                                    </li>
                                }
                            </ul>
                        </li>
                    }


                </ul>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SidenavContent);
