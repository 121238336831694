import utils from "util/utils"
import auth from "../auth"

const { sendRequest } = utils

const getRolesList = async () => {
	const token = auth.getToken()
	const route = `/roles`,
		request = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		}
	return await sendRequest(route, request)
}

const createRole = async role => {
	const token = auth.getToken()
	const route = `/roles`,
		request = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			},
			body: JSON.stringify(role)
		}
	return await sendRequest(route, request)
}

const getRoleById = async id => {
	const token = auth.getToken()
	const route = `/roles/${id}`,
		request = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			}
		}
	return await sendRequest(route, request)
}

const updateRole = async role => {
	const token = auth.getToken()
	const route = `/roles/${role.id}`,
		request = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: token
			},
			body: JSON.stringify(role)
		}
	return await sendRequest(route, request)
}

export default {
	getRolesList,
	createRole,
	getRoleById,
	updateRole
}
