import utils from 'util/utils'
import auth from '../auth'

const { sendRequest, getFileFromApi } = utils

const getRefillsList = async (startDate, endDate) => {
    const token = auth.getToken(),
        route = `/refills?startDate=${startDate}&endDate=${endDate}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const getUnitDetails = async (unitId, startDate, endDate) => {
    const token = auth.getToken(),
        route = `/unit-details?unitId=${unitId}&startDate=${startDate}&endDate=${endDate}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const createRefill = async (refill) => {
    const token = auth.getToken()
    const route = `/refills`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(refill)
        }
    return await sendRequest(route, request)
}

const getRefillById = async (id) => {
    const token = auth.getToken()
    const route = `/refills/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const authorizeRefill = async (refill) => {
    const token = auth.getToken()
    const route = `/refills/${refill.id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(refill)
        }
    return await sendRequest(route, request)
}

const notification = async (id) => {
    const token = auth.getToken(),
        route = `/refills/${id}/notification`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const confirmTemplateUpload = async (id, refill) => {
    const token = auth.getToken(),
        route = `/refills/${id}/template`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(refill)
        }
    return await sendRequest(route, request)
}

const getExcell = async (id) => {
    const token = auth.getToken(),
        route = `/refill/export/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await getFileFromApi(route, request)
}

export default {
    getRefillsList,
    getUnitDetails,
    createRefill,
    getRefillById,
    authorizeRefill,
    notification,
    confirmTemplateUpload,
    getExcell
}