import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, Redirect } from 'react-router-dom'
import auth from 'services/auth'

class UserInfo extends React.Component {

    state = {
        anchorEl: null,
        open: false,
        redirect: false
    };

    handleClick = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            auth.deleteToken()
            return <Redirect to="/login" />
        }
    }

    render() {
        return (
            <div className="user-profile d-flex flex-row align-items-center">
                {this.renderRedirect()}
                <Avatar
                    alt='...'
                    src={'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSZkd2LNMM1RoBqQ1dOHQvZ-HaHbsc7rKqqg8AARIQYpMFAWQx0'}
                    className="user-avatar "
                />
                <div className="user-detail">
                    <h4 className="user-name" onClick={this.handleClick}>{auth.getUser()} <i
                        className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
                    </h4>
                </div>
                <Menu className="user-info"
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleRequestClose}
                    PaperProps={{
                        style: {
                            minWidth: 120,
                            paddingTop: 0,
                            paddingBottom: 0
                        }
                    }}>
                    <MenuItem onClick={this.handleRequestClose}>
                        <Link to="/app/profile" style={{ textDecoration: "none" }}>
                            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                            Perfil
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        this.setRedirect()
                        this.handleRequestClose();
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
                        Cerrar sesión
          </MenuItem>
                </Menu>
            </div>
        );
    }
}

export default UserInfo;


