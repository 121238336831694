import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getTrafficLightsList = async () => {
    const token = auth.getToken()
    const route = `/traffic-lights`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const createTrafficLight = async (trafficLight) => {
    const token = auth.getToken()
    const route = `/traffic-lights`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(trafficLight)
        }
    return await sendRequest(route, request)
}

const getTrafficLightById = async (id) => {
    const token = auth.getToken()
    const route = `/traffic-lights/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateTrafficLight = async (trafficLight) => {
    const token = auth.getToken()
    const route = `/traffic-lights/${trafficLight.id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(trafficLight)
        }
    return await sendRequest(route, request)
}

const deleteTrafficLight = async (id) => {
    const token = auth.getToken()
    const route = `/traffic-lights/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getTrafficLightsList,
    createTrafficLight,
    getTrafficLightById,
    updateTrafficLight,
    deleteTrafficLight
}