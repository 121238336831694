import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getMovements = async () => {
    const route = `/getReadedFiles`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }
    return await sendRequest(route, request)
}

const deleteFile = async (id) => {
    const route = `/deleteFile/${id}`;
    const token = auth.getToken(),
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const saveFile = async (data) => {
    const route = `/import`;
    const token = auth.getToken(),
        request = {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: data
        }
    return await sendRequest(route, request)
}

export default {
    getMovements,
    deleteFile,
    saveFile
}