import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getUsersList = async () => {
    const token = auth.getToken()
    const route = `/users`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const createUser = async (user) => {
    const token = auth.getToken()
    const route = `/users`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(user)
        }
    return await sendRequest(route, request)
}

const getUserById = async (id) => {
    const token = auth.getToken()
    const route = `/users/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateUser = async (user) => {
    const token = auth.getToken()
    const route = `/users/${user.id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(user)
        }
    return await sendRequest(route, request)
}

const deleteUser = async (id) => {
    const token = auth.getToken()
    const route = `/users/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getUsersList,
    createUser,
    getUserById,
    updateUser,
    deleteUser
}