const setToken = (token) => {
    localStorage.setItem('token_medwag', token)
}

const getToken = () => {
    return localStorage.getItem('token_medwag')
}

const deleteToken = () => {
    localStorage.removeItem('token_medwag')
    localStorage.removeItem('user_medwag')
    localStorage.removeItem('medwag_permissions')
}

const setUser = (name) => {
    localStorage.setItem('user_medwag', name)
}

const getUser = () => {
    return localStorage.getItem('user_medwag')
}

const setUserId = (id) => {
    localStorage.setItem('user_id_medwag', id)
}

const getUserId = () => {
    return localStorage.getItem('user_id_medwag')
}

const setUserType = (type) => {
    localStorage.setItem('userType_medwag', type)
}

const getUserType = () => {
    return localStorage.getItem('userType_medwag')
}

const setPermissions = (permissions) => {
    localStorage.setItem('medwag_permissions', JSON.stringify(permissions))
}

const getPermissions = () => {
    try{
        return JSON.parse(localStorage.getItem('medwag_permissions'))
    } catch (error) {
        return {}
    }
}

export default {
    setToken,
    getToken,
    deleteToken,
    setUser,
    getUser,
    setUserId,
    getUserId,
    setUserType,
    getUserType,
    setPermissions,
    getPermissions
}