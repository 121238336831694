import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getUnitsList = async (groupId) => {
    const token = auth.getToken()
    const route = `/units?groupId=${groupId}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const createUnit = async (unit) => {
    const token = auth.getToken()
    const route = `/units`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(unit)
        }
    return await sendRequest(route, request)
}

const getDriver = async (unit) => {
    const token = auth.getToken()
    const route = `/units/driver/${unit}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const assingDriver = async (driver) => {
    const token = auth.getToken()
    const route = `/units/${driver.id}/driver`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                name: driver.name,
                lastName: driver.first_surname,
                secondLastName: driver.second_surname,
                email: driver.email,
                password: driver.password,
            })
        }
    return await sendRequest(route, request)
}

const getBalance = async (edenredId) => {
    const token = auth.getToken()
    const route = `/balance/${edenredId}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const deleteDriver = async (unit) => {
    const token = auth.getToken()
    const route = `/units/driver/${unit}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const getUnitById = async (id) => {
    const token = auth.getToken()
    const route = `/units/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateUnit = async (unit) => {
    const token = auth.getToken()
    const route = `/units/${unit.id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(unit)
        }
    return await sendRequest(route, request)
}

const changeStatus = async (id, status) => {
    const token = auth.getToken()
    const route = `/units/${id}/change-status/${status}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const deleteUnit = async (id) => {
    const token = auth.getToken()
    const route = `/units/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const getUnitsByGroup = async (groupId) => {
    const token = auth.getToken()
    const route = `/units-by-group/${groupId}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getUnitsList,
    createUnit,
    getUnitById,
    updateUnit,
    changeStatus,
    deleteUnit,
    getUnitsByGroup,
    assingDriver,
    getDriver,
    deleteDriver,
    getBalance
}