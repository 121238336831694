import auth from './auth'


/**
 * Recibe el nombre del módulo y acción a revisar
 * retorna si el usuario cuenta o no con permisos para acceder a esa sección (Boolean)
 * 
 * @param {string} moduleName 
 * @param {string} action optional 
 */
const hasPermission = (moduleName, action) => {
    const permissions = auth.getPermissions()
    try {
        if (action) {
            return Boolean(permissions[moduleName][action])
        } else {
            for (action in permissions[moduleName]) {
                if (permissions[moduleName][action]) {
                    return true
                }
            }
            return false
        }
    } catch (error) {
        return false
    }
}

export default {
    hasPermission
}