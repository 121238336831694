import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getMessagesList = async () => {
    const token = auth.getToken(),
        route = `/whatsapp`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}


export default {
    getMessagesList
}