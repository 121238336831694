import React, { useState } from 'react';
import { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { REQUIRED, EMAIL } from 'constants/Validations'
import Api from 'services/Api'

const initialValues = {
    email: '',
    password: ''
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(EMAIL)
        .required(REQUIRED),
    password: Yup.string()
        .required(REQUIRED)
})

const SignIn = (props) => {
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [message, setMessage] = useState('')

    const submit = async (values) => {
        setMessage('')
        setLoading(true)
        const response = await Api.auth.login(values.email, values.password)
        setLoading(false)
        if (response.success) {
            setRedirect(true)
        } else {
            setMessage(response.message || 'Hubo un problema. Intentelo mas tarde')
        }
    }
    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">
                {
                    redirect &&
                    <Redirect to="/" />
                }

                <div className="app-logo-content d-flex align-items-center justify-content-center">
                    <img src={require("assets/images/logo.png")} width="100%" alt="logo" title="logo" />
                </div>

                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        <h1>Iniciar Sesión</h1>
                    </div>

                    <div className="app-login-form">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={submit}
                        >
                            {({ values, errors, touched, handleChange, setFieldTouched }) => {
                                const change = (name, e) => {
                                    e.persist()
                                    handleChange(e)
                                    setFieldTouched(name, true, false)
                                }
                                return (
                                    <Form>
                                        <fieldset>
                                            <TextField
                                                label="Usuario (correo electrónico)"
                                                name="email"
                                                fullWidth
                                                onChange={change.bind(null, 'email')}
                                                value={values.email}
                                                helperText={touched.email ? errors.email : ''}
                                                error={touched.email && Boolean(errors.email)}
                                                margin="normal"
                                                className="mt-1 my-sm-3"
                                            />

                                            <TextField
                                                type="password"
                                                name="password"
                                                label="Contraseña"
                                                fullWidth
                                                value={values.password}
                                                onChange={change.bind(null, "password")}
                                                helperText={touched.password ? errors.password : ''}
                                                error={touched.password && Boolean(errors.password)}
                                                margin="normal"
                                                className="mt-1 my-sm-3"
                                            />

                                            {
                                                message &&
                                                <div>
                                                    <h2 style={{ color: 'red' }} >{message}</h2>
                                                </div>
                                            }

                                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                                <Button type="submit" variant="contained" color="primary" className="bg-pink text-white">
                                                    Iniciar sesión
                                                </Button>
                                            </div>

                                        </fieldset>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
            {
                loading &&
                <div className="loader-view">
                    <CircularProgress />
                </div>
            }
            <NotificationContainer />
        </div >
    );
}

export default SignIn;
