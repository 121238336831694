import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const login = async (email, password) => {
    const route = '/login',
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        }
    const response = await sendRequest(route, request)
    if (response.success) {
        auth.setToken(`${response.token.token_type} ${response.token.access_token}`)
        auth.setUser(`${response.user.name} ${response.user.lastName || ''}`)
        auth.setUserType(response.user.type)
        auth.setUserId(response.user.id)
        auth.setPermissions(response.permissions)
    }
    return response
}

export default {
    login
}