import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl'
import "assets/vendors/style"
import pinkTheme from './themes/pinkTheme'
import AppLocale from '../lngProvider';
import auth from 'services/auth'

import MainApp from 'app/index';
import SignIn from './SignIn'
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';

import permissions from 'services/permissions'
import {
    CLIENTS_MODULE,
    GROUPS_MODULE,
    TRAFFIC_LIGHTS_MODULE,
    UNITS_MODULE,
    DISPERSIONS_MODULE,
    EXTRAORDINARY_RECHARGES_MODULE,
    NOTIFICATIONS_MODULE,
    CONNECTIONS_MODULE,
    ROLES_MODULE,
    USERS_MODULE
} from 'constants/permissions';

const RestrictedRoute = ({ component: Component, token, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />}
    />;

const LoginRoute = ({ component: Component, token, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location }
                    }}
                />
                : <Component {...props} />
        }
    />;

class App extends Component {

    componentWillMount() {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    }

    render() {
        const { match, location, locale, isDirectionRTL } = this.props;
        if (location.pathname === '/') {
            let route = '/app/profile' //Ruta por defecto

            //Revisar el primer permiso disponible para redirigir a ese módulo
            if (permissions.hasPermission(CLIENTS_MODULE)) {
                route = '/app/clients'
            } else if (permissions.hasPermission(GROUPS_MODULE)) {
                route = '/app/groups'
            } else if (permissions.hasPermission(TRAFFIC_LIGHTS_MODULE)) {
                route = '/app/traffic-lights'
            } else if (permissions.hasPermission(UNITS_MODULE)) {
                route = '/app/units'
            } else if (permissions.hasPermission(DISPERSIONS_MODULE)) {
                route = '/app/dispersions'
            } else if (permissions.hasPermission(EXTRAORDINARY_RECHARGES_MODULE)) {
                route = '/app/refills'
            } else if (permissions.hasPermission(NOTIFICATIONS_MODULE)) {
                route = '/app/administration/notifications'
            } else if (permissions.hasPermission(CONNECTIONS_MODULE)) {
                route = '/app/administration/connections'
            } else if (permissions.hasPermission(ROLES_MODULE)) {
                route = '/app/administration/roles'
            } else if (permissions.hasPermission(USERS_MODULE)) {
                route = '/app/administration/users'
            }

            return (<Redirect to={route} />);
        }
        const applyTheme = createMuiTheme(pinkTheme);

        if (isDirectionRTL) {
            applyTheme.direction = 'rtl';
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
            applyTheme.direction = 'ltr';
        }

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}>
                        <RTL>
                            <div className="app-main">
                                <Switch>
                                    <RestrictedRoute path={`${match.url}app`} component={MainApp} token={auth.getToken()} />
                                    <LoginRoute path='/login' component={SignIn} token={auth.getToken()} />
                                    <Route
                                        component={asyncComponent(() => import('components/Error404'))} />
                                </Switch>
                            </div>
                        </RTL>
                    </IntlProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { sideNavColor, locale, isDirectionRTL } = settings;
    return { sideNavColor, locale, isDirectionRTL }
};

export default connect(mapStateToProps)(App);

