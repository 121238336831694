import {
    CLOSE_SNACKBAR,
    USER_REQUEST,
    GROUP_REQUEST,
    TRAFFIC_LIGHT_REQUEST,
    UNIT_REQUEST,
    CLIENT_REQUEST,
    DISPERSION_REQUEST,
    CONNECTION_REQUEST,
    REFILL_REQUEST,
    ROLE_REQUEST,
    UNIT_DETAILS_REQUEST
} from 'constants/ActionTypes';

const initialState = {
    user: {
        message: ''
    },
    group: {
        message: ''
    },
    trafficLight: {
        message: ''
    },
    unit: {
        message: ''
    },
    client: {
        message: ''
    },
    dispersion: {
        message: ''
    },
    connection: {
        message: ''
    },
    refill: {

    },
    role: {
        message: ''
    },
    unitDetails: {
        message: ''
    }
};

const api = (state = initialState, action) => {
    switch (action.type) {
        case USER_REQUEST:
            return {
                ...state,
                user: {
                    message: action.payload.message,
                    success: action.payload.success || state.user.success
                }
            };
        case GROUP_REQUEST:
            return {
                ...state,
                group: {
                    message: action.payload.message,
                    success: action.payload.success || state.group.success
                }
            }
        case TRAFFIC_LIGHT_REQUEST:
            return {
                ...state,
                trafficLight: {
                    message: action.payload.message,
                    success: action.payload.success || state.trafficLight.success
                }
            }
        case UNIT_REQUEST:
            return {
                ...state,
                unit: {
                    message: action.payload.message,
                    success: action.payload.success || state.unit.success
                }
            }
        case CLIENT_REQUEST:
            return {
                ...state,
                client: {
                    message: action.payload.message,
                    success: action.payload.success || state.client.success
                }
            }
        case DISPERSION_REQUEST:
            return {
                ...state,
                dispersion: {
                    message: action.payload.message,
                    success: action.payload.success || state.dispersion.success
                }
            }
        case CONNECTION_REQUEST:
            return {
                ...state,
                connection: {
                    message: action.payload.message,
                    success: action.payload.success || state.connection.success
                }
            }
        case REFILL_REQUEST:
            return {
                ...state,
                refill: {
                    message: action.payload.message,
                    success: action.payload.success || state.refill.success
                }
            }
        case ROLE_REQUEST:
            return {
                ...state,
                role: {
                    message: action.payload.message,
                    success: action.payload.success || state.role.success
                }
            }
        case UNIT_DETAILS_REQUEST:
            return {
                ...state,
                unitDetails: {
                    message: action.payload.message,
                    success: action.payload.success || state.unitDetails.success
                }
            }
        case CLOSE_SNACKBAR:
            return {
                user: {
                    message: ''
                },
                group: {
                    message: ''
                },
                trafficLight: {
                    message: ''
                },
                unit: {
                    message: ''
                },
                client: {
                    message: ''
                },
                dispersion: {
                    message: ''
                },
                connection: {
                    message: ''
                },
                refill: {
                    message: ''
                },
                role: {
                    message: ''
                },
                unitDetails: {
                    message: ''
                }
            }
        default:
            return state;
    }
};

export default api;
