//Módulos
export const CLIENTS_MODULE = 'clients'
export const GROUPS_MODULE = 'groups'
export const TRAFFIC_LIGHTS_MODULE = 'trafficLights'
export const UNITS_MODULE = 'units'
export const DISPERSIONS_MODULE = 'dispersions'
export const EXTRAORDINARY_RECHARGES_MODULE = 'extraordinaryRecharges'
export const NOTIFICATIONS_MODULE = 'notifications'
export const CONNECTIONS_MODULE = 'connections'
export const ROLES_MODULE = 'roles'
export const USERS_MODULE = 'users'
export const REPORTS_MODULE = 'reports'
export const FILES_MODULE = 'files'

//Acciones
export const CREATE_ACTION = 'create'
export const VIEW_ACTION = 'view'
export const EDIT_ACTION = 'edit'
export const DELETE_ACTION = 'delete'
export const DOWNLOAD_ACTION = 'download'
export const AUTHORIZE_ACTION = 'authorize'
export const DISPERSION_REPORT = 'dispersion'
export const COMBUSTIBLE_REPORT = 'combustible'
export const TRAFFIC_LIGHTS_REPORT = 'trafficLight'
export const RELOADS_REPORT = 'reload'