import utils from 'util/utils'
import auth from '../auth'
const { sendRequest } = utils

const getComboList = async (combo) => {
    const token = auth.getToken()
    const route = `/configs?${combo}`,
           request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            
        }
    return await sendRequest(route, request)
}

const getGasStations = async (origin) => {
    const token = auth.getToken()
    const route = `/gasStationsByOrigin`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(origin)
        }
    return await sendRequest(route, request)
}

const getGasOptions = async (origin) => {
    const token = auth.getToken()
    const route = `/getTypesOfGas`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(origin)
        }
    return await sendRequest(route, request)
}


export default { getComboList, getGasStations, getGasOptions };