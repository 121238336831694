import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getConnectionsList = async () => {
    const token = auth.getToken()
    const route = `/connections`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const createConnection = async (connection) => {
    const token = auth.getToken()
    const route = `/connections`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(connection)
        }
    return await sendRequest(route, request)
}

const getConnectionById = async (id) => {
    const token = auth.getToken()
    const route = `/connections/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateConnection = async (connection, id) => {
    const token = auth.getToken()
    const route = `/connections/${id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(connection)
        }
    return await sendRequest(route, request)
}

const deleteConnection = async (id) => {
    const token = auth.getToken()
    const route = `/connections/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getConnectionsList,
    createConnection,
    getConnectionById,
    updateConnection,
    deleteConnection
}