import numeral from 'numeral'
import { apiHost } from 'constants/api'
const baseUrl = `${apiHost}/v1`

const cloneObject = (object) => {
    return JSON.parse(JSON.stringify(object))
}

const formatMoney = (number) => {
    const myNumeral = numeral(number)
    return myNumeral.format('$0,0.00')
}

const formatDecimals = (number) => {
    const myNumeral = numeral(number)
    return myNumeral.format('0,0.00')
}

const formatDateToString = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('es-MX', options)
}

const formatDateToTimeString = (date) => {
    const hourNumeral = numeral(date.getHours()),
        minutesNumeral = numeral(date.getMinutes())
    return `${hourNumeral.format('00')}:${minutesNumeral.format('00')}`
}

/**
 * Recibe la cantidad y el tipo y retorna una cadena de la forma '5 litros' o '300 pesos'
 * 
 * @param {number} amount 
 * @param {string} type ['lt', 'money']
 */
const formatCostString = (amount, type) => {
    let costString = `${amount} `
    costString += type === 'lt' ? 'litros' : 'pesos'
    return costString
}

const sendRequest = async (route, request) => {
    let response
    try {
        response = await fetch(baseUrl + route, request)

    } catch (error) {
        //console.log('En el catch', error)
        return { success: false }
    }
    try {
        const data = await response.json()
        return data
    } catch (error) {
        return { success: false }
    }
}

const getFileFromApi = async (route, request) => {
    let response
    try {
        response = await fetch(baseUrl + route, request)

    } catch (error) {
        //console.log('En el catch', error)
        return false
    }
    try {
        if(response.status !== 200) {
            return await response.json()
        }
        const data = await response.blob()
        return data
    } catch (error) {
        return false
    }
}

const getSelectItem = (item, options) => {
    options.some(option => {
        if (option.value === item) {
            item = option
            return true
        }

    })
    return item
}

const getLabelOptionFromValue = (item, options) => {
    options.some(option => {
        if (option.value === item) {
            item = option.label
            return true
        }
    })
    return item
}

const getFirstDayOfCurrentMonth = () => {
    const firstDay = new Date()
    firstDay.setDate(1)
    return firstDay
}

const getLastDayOfCurrentMonth = () => {
    const lastDate = new Date()
    lastDate.setDate(1)                             //Poner el dia 1
    lastDate.setMonth(lastDate.getMonth() + 1)      //Del siguiente mes
    lastDate.setDate(lastDate.getDate() - 1)        //Quitarle un día a la fecha para obtener el último dia del mses actual
    return lastDate
}

export default {
    cloneObject,
    formatMoney,
    formatDecimals,
    formatDateToString,
    formatDateToTimeString,
    formatCostString,
    sendRequest,
    getFileFromApi,
    getSelectItem,
    getLabelOptionFromValue,
    getFirstDayOfCurrentMonth,
    getLastDayOfCurrentMonth
}