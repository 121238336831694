import auth from './auth'
import clients from './clients'
import config from './configs'
import connections from './connections'
import dispersions from './dispersions'
import groups from './groups'
import trafficLights from './trafficLights'
import units from './units'
import users from './users'
import refills from './refills'
import roles from './roles'
import reports from './reports'
import whatsapp from './whatsapp'
import notifications from './notifications'
import files from './files'

export default {
    auth,
    clients,
    config,
    connections,
    dispersions,
    groups,
    trafficLights,
    units,
    users,
    refills,
    roles,
    reports,
    whatsapp,
    notifications,
    files
}