import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';

import permissions from 'services/permissions'
import {
    CLIENTS_MODULE,
    GROUPS_MODULE,
    TRAFFIC_LIGHTS_MODULE,
    UNITS_MODULE,
    DISPERSIONS_MODULE,
    EXTRAORDINARY_RECHARGES_MODULE,
    NOTIFICATIONS_MODULE,
    CONNECTIONS_MODULE,
    ROLES_MODULE,
    USERS_MODULE,
    REPORTS_MODULE,
    FILES_MODULE
} from 'constants/permissions'

class App extends React.Component {

    render() {
        const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <Sidebar />
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                            <TopNav styleName="app-top-header" />}
                        <Header />
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                            <TopNav />}
                    </div>

                    <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                            <Switch>
                                {
                                    permissions.hasPermission(CLIENTS_MODULE)
                                        ? <Route path={`${match.url}/clients`} component={asyncComponent(() => import('./routes/Clients'))} />
                                        : <Route path={`${match.url}/clients`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                {
                                    permissions.hasPermission(GROUPS_MODULE)
                                        ? <Route path={`${match.url}/groups`} component={asyncComponent(() => import('./routes/Groups'))} />
                                        : <Route path={`${match.url}/groups`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                {
                                    permissions.hasPermission(TRAFFIC_LIGHTS_MODULE)
                                        ? <Route path={`${match.url}/traffic-lights`} component={asyncComponent(() => import('./routes/TrafficLights'))} />
                                        : <Route path={`${match.url}/traffic-lights`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                {
                                    permissions.hasPermission(UNITS_MODULE)
                                        ? <Route path={`${match.url}/units`} component={asyncComponent(() => import('./routes/Units'))} />
                                        : <Route path={`${match.url}/units`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                {
                                    permissions.hasPermission(DISPERSIONS_MODULE)
                                        ? <Route path={`${match.url}/dispersions`} component={asyncComponent(() => import('./routes/Dispersions'))} />
                                        : <Route path={`${match.url}/dispersions`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                {
                                    permissions.hasPermission(EXTRAORDINARY_RECHARGES_MODULE)
                                        ? <Route path={`${match.url}/refills`} component={asyncComponent(() => import('./routes/Refills'))} />
                                        : <Route path={`${match.url}/refills`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                <Route path={`${match.url}/profile`} component={asyncComponent(() => import('./routes/Profile'))} />
                                {
                                    (permissions.hasPermission(NOTIFICATIONS_MODULE)
                                        || permissions.hasPermission(CONNECTIONS_MODULE)
                                        || permissions.hasPermission(ROLES_MODULE)
                                        || permissions.hasPermission(USERS_MODULE)
                                        || permissions.hasPermission(FILES_MODULE)
                                    )
                                        ? <Route path={`${match.url}/administration`} component={asyncComponent(() => import('./routes/Administration'))} />
                                        : <Route path={`${match.url}/administration`} component={asyncComponent(() => import('containers/NoPermission'))} />
                                }
                                {
                                    permissions.hasPermission(REPORTS_MODULE) &&
                                    <Route path={`${match.url}/reports`} component={asyncComponent(() => import('./routes/Reports'))} />
                                }
                                <Route component={asyncComponent(() => import('components/Error404'))} />
                            </Switch>
                        </div>
                        <Footer />
                    </main>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ settings }) => {
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App));