import utils from 'util/utils'
import auth from '../auth'
const { sendRequest, getFileFromApi } = utils


const creteDispersion = async (dispersion) => {
    const token = auth.getToken()
    const route = `/dispersion`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dispersion)

        }
    return await sendRequest(route, request)
}

const getDipesionList = async (startDate, endDate) => {
    const token = auth.getToken()
    const route = `/dispersion?startDate=${startDate}&endDate=${endDate}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const getDispersionById = async (id) => {
    const token = auth.getToken()
    const route = `/dispersion/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateDispersion = async (dispersion) => {
    const token = auth.getToken()
    const route = `/dispersion/${dispersion.id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dispersion)
        }
    return await sendRequest(route, request)
}

/**
 * 
 * @param {Object} dispersion 
 * El objeto dispersion debe tener
 *  {int} id
 *  {status} string
 *  {authorizationReasons} string
 */
const authorizeDispersion = async (dispersion) => {
    const token = auth.getToken()
    const route = `/dispersion/${dispersion.id}/authorize`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dispersion)
        }
    return await sendRequest(route, request)
}

const deleteDispersion = async (id) => {
    const token = auth.getToken()
    const route = `/dispersion/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const confirmTemplateUpload = async (id, dispersion) => {
    const token = auth.getToken(),
        route = `/dispersion/${id}/template`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dispersion)
        }
    return await sendRequest(route, request)
}

const getExcell = async (id) => {
    const token = auth.getToken(),
        route = `/dispersion/export/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await getFileFromApi(route, request)
}

export default {
    creteDispersion,
    getDipesionList,
    getDispersionById,
    updateDispersion,
    authorizeDispersion,
    deleteDispersion,
    confirmTemplateUpload,
    getExcell
}
