import utils from 'util/utils'
import auth from '../auth'

const { getFileFromApi, sendRequest } = utils

const getDispersionReport = async (groupId, unitsDisable, startDate, endDate) => {
    const token = auth.getToken(),
        route = `/report-dispersion?groupId=${groupId}&unitsDisable=${unitsDisable}&starDate=${startDate}&endDate=${endDate}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await getFileFromApi(route, request)
}

const getGasolineReport = async (groupId, startDate, endDate) => {
    const token = auth.getToken(),
        route = `/report-gasolineConsume?groupId=${groupId}&starDate=${startDate}&endDate=${endDate}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await getFileFromApi(route, request)
}

const getTrafficLightReport = async (groupId, startDate, endDate) => {
    const token = auth.getToken(),
        route = `/report-trafficLight?groupId=${groupId}&starDate=${startDate}&endDate=${endDate}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await getFileFromApi(route, request)
}

const getReloadsReport = async (data) => {
    const token = auth.getToken(),
        route = `/filterReportResults`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(data)
        }
    return await sendRequest(route, request)
}

const getReloadsReportPetition = async (data) => {
    const token = auth.getToken(),
        route = `/exportFiles`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(data)
        }
    return await sendRequest(route, request)
}

const getReloadsReportFile = async (id) => {
    const token = auth.getToken(),
        route = `/checkFileToExport/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getDispersionReport,
    getGasolineReport,
    getTrafficLightReport,
    getReloadsReport,
    getReloadsReportPetition,
    getReloadsReportFile
}