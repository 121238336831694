import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getClientsList = async () => {
    const token = auth.getToken()
    const route = `/clients`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const createClient = async (client) => {
    const token = auth.getToken()
    const route = `/clients`,
        request = {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: client
        }
    return await sendRequest(route, request)
}

const getClientById = async (id) => {
    const token = auth.getToken()
    const route = `/clients/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateClient = async (client, id) => {
    const token = auth.getToken()
    const route = `/clients/${id}`,
        request = {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: client
        }
    return await sendRequest(route, request)
}

const deleteClient = async (id) => {
    const token = auth.getToken()
    const route = `/clients/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getClientsList,
    createClient,
    getClientById,
    updateClient,
    deleteClient
}