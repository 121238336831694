import utils from 'util/utils'
import auth from '../auth'

const { sendRequest } = utils

const getGroupsList = async () => {
    const token = auth.getToken()
    const route = `/groups`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}




const createGroup = async (group) => {
    const token = auth.getToken()
    const route = `/groups`,
        request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(group)
        }
    return await sendRequest(route, request)
}

const getGroupById = async (id) => {
    const token = auth.getToken()
    const route = `/groups/${id}`,
        request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

const updateGroup = async (group) => {
    const token = auth.getToken()
    const route = `/groups/${group.id}`,
        request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(group)
        }
    return await sendRequest(route, request)
}

const deleteGroup = async (id) => {
    const token = auth.getToken()
    const route = `/groups/${id}`,
        request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
    return await sendRequest(route, request)
}

export default {
    getGroupsList,
    createGroup,
    getGroupById,
    updateGroup,
    deleteGroup
}